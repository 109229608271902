import React from "react"
import Footer from "../components/footer"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import {Col, Row} from "react-bootstrap";
import AppAccordion from "../components/AppAccordion";
import {faqsPageFaqs} from "../_shared/constants";

const Faqs = ({ location }) => {
    return (
        <Layout>
            <Seo title="Team Coaches Book A Demo" />
            <NavBar location={location} />
            <div style={{ minHeight: "92vh", margin: "0" }} className="d-flex flex-column justify-content-between">
                <Row className="faqs-container m-0">
                    <Col className="text-center col-12 mt-5 mb-2">
                        <h2 className="class-header">Frequently Asked Questions </h2>
                    </Col>
                    <Col className="col-12 col-md-10 col-lg-10 mx-auto pb-5">
                        <Row className="m-0">
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={faqsPageFaqs.sectionOne}/>
                            </Col>
                            <Col className="col-1 hide-on-small-screen" />
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={faqsPageFaqs.sectionTwo}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Footer />
            </div>
        </Layout>
    )
}

export default Faqs
